<template>
  <section id="sub_header_box" class="annual">
    <div class="w1300">

      <div class="sub_header_description txt-white page_header_space">
        <h3 class="sub_header_description_title">{{ eventHeaderItems.name }}</h3>
        <!-- <p>今年主題著重於居家安寧病人照護實務經驗。</p>
        <p>藉由實務經驗分享，互相學習交流，讓居家醫療更能符合患者需求。</p> -->
        <div class="bottom">
          <p class="type-A small" v-if="hasFractionA">
            <span class="tag">A類</span>
            <span class="credit">．{{ eventHeaderItems.fraction_a }}學分</span>
          </p>
          <p class="type-B small" v-if="hasFractionB">
            <span class="tag">B類</span>
            <span class="credit">．{{ eventHeaderItems.fraction_b }}學分</span>
          </p>
        </div>
      </div>

      <img class="heart_bg" src="@/statics/img/about/bg_heart.webp" alt="">

    </div>
  </section>
</template>

<script>
export default {
  name: 'AnnualHeader',
  props: [
    'eventHeaderItems',
  ],
  data() {
    return {

    };
  },
  computed: {
    hasFractionA() {
      if (this.eventHeaderItems.fraction_a
        && parseInt(this.eventHeaderItems.fraction_a, 10) > 0) {
        return true;
      }
      return false;
    },
    hasFractionB() {
      if (this.eventHeaderItems.fraction_b
        && parseInt(this.eventHeaderItems.fraction_b, 10) > 0) {
        return true;
      }
      return false;
    },
    // hasEventNo() {
    //   if (this.eventHeaderItems.eventNo) {
    //     return true;
    //   }
    //   return false;
    // },
  },
};
</script>
